<template>
  <v-app>
    <WelcomeSnack />
    <v-overlay
      v-model="isAppLoading"
      activator="parent"
      scroll-strategy="block"
      location-strategy="connected"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular
    ></v-overlay>
    <v-main>
      <router-view />
    </v-main>

    <PWAPrompt />

    <v-dialog v-model="dialog" width="auto" scrollable>
      <v-card
        ><v-card-title>Helpbooster Alpha 1_0_1a</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <h2>
            This alpha release is a pre-beta version intended for a limited
            audience to uncover early-stage bugs, glitches, and usability
            issues.
          </h2>
          <p>
            Some features may be incomplete or disabled, and performance may not
            be optimized.
          </p>

          <h2>Guidelines for Alpha Testing:</h2>

          <h3>Explore Thoroughly:</h3>
          <ul>
            <li>Interact with all available features and functionalities.</li>
            <li>Test various user scenarios to uncover potential issues.</li>
          </ul>

          <h3>Document and Report Bugs:</h3>
          <ul>
            <li>
              Use our designated bug tracking system or template to report any
              bugs or unexpected behaviors.
            </li>
            <li>
              Provide detailed information, including steps to reproduce the
              issue, expected vs. actual results, and your system
              specifications.
            </li>
          </ul>

          <h3>Performance Testing:</h3>
          <ul>
            <li>
              Assess the software's performance under different conditions
              (e.g., varying network speeds, system loads).
            </li>
            <li>
              Report any instances of slow responsiveness or system resource
              issues.
            </li>
          </ul>

          <h3>Compatibility Testing:</h3>
          <ul>
            <li>
              Test the software on different operating systems, browsers, or
              devices as specified in the compatibility matrix.
            </li>
            <li>Report any compatibility issues encountered.</li>
          </ul>

          <h3>Security Testing:</h3>
          <ul>
            <li>
              If applicable, test the software for potential security
              vulnerabilities.
            </li>
            <li>Report any security concerns promptly.</li>
          </ul>

          <h3>Keep it dense:</h3>
          <ul>
            <li>
              Submit combined status reports, summarizing your testing
              activities, findings, and progress.
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" variant="text" @click="dialog = false">
            Got it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="footer-text" v-show="!isInsideForm">
      <a
        href="https://www.j3debt.co.uk/our-fees-explained/"
        target="_blank"
        class="j3linkfooter"
        >Our Fees Explained
      </a>
      |
      <a
        href="https://www.j3debt.co.uk/customer-charter/"
        target="_blank"
        class="j3linkfooter"
        >Customer Charter
      </a>
      |
      <a
        href="https://www.j3debt.co.uk/privacy-policy/"
        target="_blank"
        class="j3linkfooter"
        >Cookies &amp; Privacy Policy</a
      >
    </div>
    <div class="text-center d-flex flex-column">
      <div></div>

      <div style="display: none">
        {{ new Date().getFullYear() }} — <strong>J3 Debt Solutions</strong>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import PWAPrompt from "./components/PWAPrompt.vue";
//import FilterWin from './components/Filter.vue'
//import BottomNav from './components/BottomNav.vue'
import CoreDrawer from "./components/Core/Drawer.vue";
//import AppBar from "./components/Core/AppBar.vue";
import WelcomeSnack from "./components/Core/WelcomeSnack.vue";

//import CoreView from "./components/Core/View.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: "App",
  components: {
    PWAPrompt,
    // FilterWin,
    WelcomeSnack,
    //AppBar,
    // CoreView,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    allowNotification: function () {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }

        const messaging = getMessaging();
        getToken(messaging, {
          vapidKey:
            "BO1v2Ntwos5cBUxbAHQQ9CEH7qxY7odk5CLozAO7C7XTta85bUs59n4H3DXSkGTEpPT6tscwllA9r1DVPWBgUrM",
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              console.log(currentToken);
              // ...
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one.",
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });

        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
          this.$store.commit("ADD_NOTIFICATION", {
            message: payload.notification.body,
          });

          // ...
        });
      });
    },
  },

  created() {
    document.title =
      "J3 Debt Solutions - Help Booster | Get Your Finances Back Under Control";
    if (Notification.permission !== "granted") {
      this.dialog = false;
    } else {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey:
          "BO1v2Ntwos5cBUxbAHQQ9CEH7qxY7odk5CLozAO7C7XTta85bUs59n4H3DXSkGTEpPT6tscwllA9r1DVPWBgUrM",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log(currentToken);
            this.$store.commit("SET_FIREBASETOKE", {
              token: currentToken,
            });
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one.",
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        this.$store.commit("ADD_NOTIFICATION", {
          message: payload.notification.body,
        });

        // ...
      });
    }
  },
  computed: {
    ...mapState(["firebase_token", "isAppLoading", "isInsideForm"]),
  },
  mounted() {
    // let gdprSTag = document.createElement("script");
    // gdprSTag.setAttribute(
    //   "src",
    //   "https://cdn-cookieyes.com/client_data/278501c2a27d1df76f3cfc6f/script.js",
    // );
    // document.head.appendChild(gdprSTag);
    //this.$store.dispatch("USER_AUTH");
    //this.$store.dispatch("startAppLoading");
    //
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,400");

html,
body {
  font-family: "Nunito", sans-serif;
}

*,
#app {
  font-family: "Nunito", sans-serif;
}

:root {
  --background: #ebecf0;
  --bright: #ee6c4d;
  --dark: #3d5a80;
  --darker: #293241;
  --accent: #98c1d9;
}

.v-main,
body,
.theme--light.v-application {
  background-color: #ebecf0 !important;
}
#app,
#app:before,
#app:after {
  box-sizing: border-box;

  color: inherit;
  max-width: 430px;
  min-width: 350px;
  width: 100%;
}
#app {
  margin: 0 auto;
}
*:focus {
  outline: 2px solid var(--bright);
  outline-offset: 2px;
}

*::selection {
  background: var(--accent);
  color: var(--light);
}

.v-content {
}
.v-application p {
  margin-bottom: 10px;
}
.v-btn {
  font-weight: 600 !important;

  text-transform: none !important;
}
.v-btn.rounded-j3 {
  border-radius: 7px;
}
.v-btn.cta {
  border-radius: 7px;

  font-weight: bold;
  letter-spacing: 0px;
  font-size: 16px;
}
.footer-text {
  font: normal normal normal 12px/16px Nunito;
  letter-spacing: 0px;
  color: #3c4043;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.footer-text a {
  color: #3c4043;
  text-decoration: none;
}
</style>
