<template>
	<div>
		<v-card
			:timeout="-1"
			:value="true"
			top
			width="100%"
			color="#58b3dc"
			background-color="#58b3dc"
			compact
			elevation="0"
			tile
		>
			<p
				class="text-center"
				style="
					font-size: 12px;
					color: #fff;
					margin-bottom: 0px !important;
				"
			>
				<i>Powered by J3 Debt Solutions</i>
			</p>
		</v-card>
		<div class="appbg pt-6" v-if="isShowLogo">
			<v-img
				src="@/assets/graphic_title_logo.png"
				contain
				:height="16"
				width="239"
				class="mx-auto"
			/>
			<div
				style="
					text-align: center;
					letter-spacing: 0px;
					color: #f4f4f5;
					font-size: 14px;
					font-weight: bold;
				"
			>
				Fill out the rest and we’ll do our best.
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["isShowLogo"]),
	},
};
</script>
<style scoped>
.appbg {
	background: linear-gradient(to right, #2e88bf, #4cb4d4);

	width: 100%;
	height: 80px; /* Adjust the height as needed */
	/* Add other styles as needed */
}
</style>
